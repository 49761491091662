import { IGetAuthUrlParams } from './auth';
import { eRsRequestCommonResult } from './rs';
import { IUser } from './user';

export enum eKycStatus
{
    none = 0,
    pending = 1,
    complete_succeeded = 2,
    complete_fail_retryable = 3,
    complete_fail_final = 4,
    deleted = 5
}

export enum eVerificationLevel
{
    user = 0,
    phone_number = 1,
    social = 2,
    kyc_basic = 3,
    kyc_full = 4,
}

export interface IUsersKyc
{
    user: string;
    status: eKycStatus;
    verification_level: eVerificationLevel;
    createdAt: Date;
    updatedAt: Date;
}

export type KycAddParams = Omit<IUsersKyc, 'createdAt' | 'updatedAt'>;

export interface IGetKycFlowUrlParams extends IGetAuthUrlParams
{
    verification_level?: eVerificationLevel; //default eVerificationLevel.kyc_basic
    kyc_sub_project?: string;
}

export enum eKycResult
{
    fail_by_billing = -6,
    fail_by_user_not_found = -7,
    fail_by_kyc_deleted = -8,
}

export interface IKycTopLevelStatusResponse
{
    request_id: number;
    result: eKycResult | eRsRequestCommonResult;
    verification_level: number;
    create_dt: number;
    review_create_dt: number;
    review_complete_dt: number;
}

export interface IKycDetailedStatusResponse
{
    request_id: number;
    result: eRsRequestCommonResult | eKycResult;
    verification_level: eVerificationLevel;
    user_status: eKycStatus;
    kyc_id: string;
    first_name: string;
    last_name: string;
    birthday: string;
    country_a2: string;
    country_a3: string;
    provided_country_a2: string;
    provided_country_a3: string;
    address_country_a2: string;
    address_country_a3: string;
    phone_number_country_a2: string;
    phone_number_country_a3: string;
    phone_number_country_code: string;
    ip_countries_a2: string[];
    ip_countries_a3: string[];
    moderation_comment: string;
    reject_reasons: string[];
    support_link: string;
    create_dt: number;
    review_create_dt: number;
    review_complete_dt: number;
    expiration_dt: number;
    isManual?: boolean;
}

export interface IKycUserInfo
{
    id: number;
    isManual: boolean;
    user: IUserFromKyc;
    status: IKycStatus;
    verificationLevel: IVerificationLevel;
    createdAt: Date;
    updatedAt: Date;
}

export interface IKycStatus
{
    createdAt: Date;
    id: number;
    name: eKycStatus;
    updatedAt: Date;
    publishedAt: Date;
}
export interface IUserFromKyc
{
    blocked: boolean;
    confirmed: boolean;
    createdAt: Date;
    email: string;
    id: number;
    provider: string;
    updatedAt: Date;
    username: string;
}

export interface IVerificationLevel
{
    createdAt: Date;
    id: number;
    name: eVerificationLevel;
    updatedAt: Date;
    publishedAt: Date;
}